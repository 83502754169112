import { mapState } from "vuex";

function formFieldsInitialState () {
  return {
    fuel: null
  }
}

export default {
  name: 'Fuel',
  data () {
    return {
      dataForm: formFieldsInitialState()
    }
  },
  computed: {
    ...mapState ({
      fuelTypeList: state => state.base.fuelType
    })
  },
  methods: {
    /** Add vehicle type */
    addFuelType () {
      const body = JSON.stringify({
        name: this.dataForm.fuel
      })
      this.$api.post(`admin/fuel`, body).then(response => {
        if (response.status === 'success') {
          this.$store.dispatch('getFuelType')
          this.$data.dataForm = formFieldsInitialState()
        }
      })
    },

    openSwal () {
      return this.$swal({
         title: this.$i18n.t('title.sureRemoveFuel'),
         icon: 'warning',
         buttons: [this.$i18n.t('btn.cancel'), this.$i18n.t('btn.yes')]
       })
     },
 
    handleDeleteFuelType (fuel) {
      this.openSwal()
      .then((yes) => yes && this.deleteFuelType(fuel))
      
    },

    deleteFuelType (fuel) {
      const params = new URLSearchParams()
      params.set('uid', fuel.uid)

      this.$api.delete(`admin/fuel/?${params}`).then(response => {
        if (response.status === 'deleted') {
          this.$store.dispatch('getFuelType')
        }
      })
    }
  }
}
