<template lang="pug">
  include ../../../assets/pug/index

  div
    v-list-group(
      v-for="(brand, indexBrand) in items"
      :key="brand.id"
      @click.once="revealCarModel(brand.uid, indexBrand)"
    )
      template(v-slot:activator) 
        v-list-item-title {{ brand.name }}
        v-icon(@click.stop="deleteHandlers[0](brand)" color="red" medium) mdi-delete
      v-list-item(v-if="!('models' in brand)")
        i.icon-spinner.mx-4.my-4
    
      v-list-item(
        v-else-if="('models' in brand) && brand.models.length"
        v-for="(model, index) in brand.models"
        :key="model.id"
      )
        v-list-item-title.pl-5 - {{ model.name }}
          v-btn(icon :loading="model.isLoad" color="error")
            v-icon(
              @click="deleteHandlers[1](model, indexBrand)"
              color="red"
              medium
            ) mdi-delete
      
      v-list-item(v-else)
        v-list-item-title.pl-5 {{$t("noData")}}
</template>

<script>

export default {
  name: "AutoList",
  props: {
    items: Array,
    deleteHandlers : Array,
    revealCarModel: Function,
  }
}
</script>
