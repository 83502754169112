import {mapState} from "vuex";

function formFieldsInitialState () {
  return {
    color: null
  }
}

export default {
  name: 'Colors',
  data () {
    return {
      dataForm: formFieldsInitialState()
    }
  },
  computed: {
    ...mapState ({
      colorsList: state => state.base.colors
    })
  },
  methods: {
    addColor () {
      const body = JSON.stringify({
        name: this.dataForm.color
      })
      this.$api.post(`admin/color`, body).then(response => {
        if (response.status === 'success') {
          this.$store.dispatch('getColors')
          this.$data.dataForm = formFieldsInitialState()
        }
      })
    },

    openSwal () {
     return this.$swal({
        title: this.$i18n.t('title.sureRemoveColor'),
        icon: 'warning',
        buttons: [this.$i18n.t('btn.cancel'), this.$i18n.t('btn.yes')]
      })
    },

    handleDeleteColor (color) {
      this.openSwal()
      .then((yes) => yes && this.deleteColor(color))
      
    },

    deleteColor (color) {
      const params = new URLSearchParams()
      params.set('uid', color.uid)

      this.$api.delete(`admin/color/?${params}`).then(response => {
        if (response.status === 'deleted') {
          this.$store.dispatch('getColors')
        }
      })
    }
  }
}
