import Auto from './Auto/Auto.vue'
import VehicleType from './VehicleType/VehicleType.vue'
import Colors from './Colors/Colors.vue'
import Fuel from './Fuel/Fuel.vue'

export default {
  name: 'Base',
  components: {
    Auto,
    VehicleType,
    Colors,
    Fuel
  },
  data () {
    return {
      tabs: null
    }
  },
  mounted () {
    this.$store.dispatch('getCarBrands')
    this.$store.dispatch('getVehicleType')
    this.$store.dispatch('getColors')
    this.$store.dispatch('getFuelType')
    this.$store.commit('setDataTitle', this.$i18n.t('database'))
  }
}
