import { mapState } from 'vuex'

function formFieldsInitialState () {
  return {
    vehicleType: null
  }
}

export default {
  name: 'VehicleType',
  data () {
    return {
      dataForm: formFieldsInitialState()
    }
  },
  computed: {
    ...mapState ({
      vehicleTypesList: state => state.base.vehicleType
    })
  },
  methods: {
    /** Add vehicle type */
    addVehicleType () {
      const body = JSON.stringify({
        name: this.dataForm.vehicleType
      })
      this.$api.post(`admin/vehicle-type`, body).then(response => {
        if (response.status === 'success') {
          this.$store.dispatch('getVehicleType')
          this.$data.dataForm = formFieldsInitialState()
        }
      })
    },

    openSwal () {
      return this.$swal({
         title: this.$i18n.t('title.sureRemoveTypeVehicle'),
         icon: 'warning',
         buttons: [this.$i18n.t('btn.cancel'), this.$i18n.t('btn.yes')]
       })
     },
 
    handleDeleteVehicleType (type) {
      this.openSwal()
      .then((yes) => yes && this.deleteVehicleType(type))
      
    },

    deleteVehicleType (type) {
      const params = new URLSearchParams()
      params.set('uid', type.uid)

      this.$api.delete(`admin/vehicle-type/?${params}`).then(response => {
        if (response.status === 'deleted') {
          this.$store.dispatch('getVehicleType')
        }
      })
    }
  }
}
