<template lang="pug">
  include ../../../assets/pug/index
  v-card.pb-0(flat)
    div
      .auto-actions.d-flex.justify-end.mb-7
        .auto-dropdown-wrap
          +btn('primary', 'addCarMark')(@click="toggleViewAddBrand").mx-3.mt-3
          AddMark(
            v-if="isAddMark"
            :handleAdd="handleaddNewCarBrand"
            :dataForm="dataForm" 
            :isLoad="isLoad.addCarBrand"
            @closed="(val) => isAddMark = val"
            )
        .auto-dropdown-wrap
          +btn('primary', 'addCarModel')(@click="toggleViewAddModel").mx-3.mt-3
          AddModel(
            v-if="isAddModel"
            :handleAdd="handleAddCarModel" 
            :dataForm="dataForm" 
            :isLoad="isLoad.addCarModel"
             @closed="(val) => isAddModel = val"
          )

      .auto-search-input.w-30
        +input('searchText', 'enterCarMark')(prepend-icon="search")
      AutoList.mt-6(
        v-if="findedCarBrands.length || carBrandsList.length"
        :items="findedCheck"
        :deleteHandlers="[handleDeleteCarBrand, handleDeleteCarModel]"
        :revealCarModel="revealCarModel"
      )
      div(v-else).d-flex.align-center.justify-center.mt-8
        i.icon-spinner
      div(v-if="!isFound")
        +h2("notify.notFound")
      v-pagination.mt-8(
        v-model="page"
        :length="10"
        :total-visible="7"
        @input="updatePage"
        :disabled="!isReadyPage"
        circle
      )
</template>

<script>
import { mapState, mapActions } from "vuex"
import AutoList from './AutoList.vue'

import AddMark from './Create/Mark.vue'
import AddModel from './Create/Model.vue'

function formFieldsInitialState () {
  return {
    carBrand: null,
    selectedCarBrand: null,
    carModel: null,
  }
}

export default {
  name: "Auto",
  components: {
    AddMark,
    AddModel,
    AutoList
  },
  data () {
    return {
      dataForm: formFieldsInitialState(),
      searchText: '',
      page: 1,
      pageSize: 20,
      listCount: 0,
      pageList: [],
      findedCarBrands: [],
      isReadyPage: false,
      isFound: true,
      isLoad: {
        addCarBrand: false,
        addCarModel: false
      },
      isAddMark: false,
      isAddModel: false
    }
  },  
  watch: {
    searchText (value) {
      this.searchingCarBrand(value)
    }
  },
  computed: {
    ...mapState ({
      carBrandsList: state => state.base.carBrand
    }),
    findedCheck () {
      return this.findedCarBrands.length || !this.isFound ? this.findedCarBrands : this.pageList
    }
  },
  created () {
    this.unwatchCarBrandsList = this.$watch('carBrandsList', (newVal) => {
      if (newVal) {
        this.isReadyPage = true
        this.initPage()
        this.unwatchCarBrandsList()
      }
    })
    this.updatePage(this.page)
  },
  methods: {
    ...mapActions(
      [
      'addCarManufacturer',
      'addNewCarModel', 
      'deleteCarManufacturer', 
      'deleteCarModel'
      ]),
    
    searchingCarBrand (text) {
      this.findedCarBrands = !text ? '' : 
      this.carBrandsList.filter(item => item.name.toLowerCase().startsWith(text.toLowerCase()))
      this.isFound = !this.findedCarBrands.length && text ? false : true 
    },

    openSwal (title) {
      return this.$swal({
          title: this.$i18n.t(`title.${title}`),
          icon: 'warning',
          buttons: [this.$i18n.t('btn.cancel'), this.$i18n.t('btn.yes')]
        })
    },

    toggleViewAddBrand () {
      this.isAddMark = !this.isAddMark
      this.isAddModel = false
    },

    toggleViewAddModel () {
      this.isAddModel = !this.isAddModel
      this.isAddMark = false
    },
    /** Add new car brand */
    handleaddNewCarBrand () {
      this.isLoad.addCarBrand = true
      this.addCarManufacturer(this.dataForm.carBrand)
        .finally(() => {
          this.isLoad.addCarBrand = false 
        })
          .then(() => { this.isAddMark = false })
    },

    /** Delete car brand */
    handleAddCarModel () {
      this.isLoad.addCarModel = true
      const data = {
        id: this.dataForm.selectedCarBrand.id,
        name: this.dataForm.carModel,
        manufacturer: this.dataForm.selectedCarBrand.uid
      }
      this.addNewCarModel(data)
        .then(() => this.$data.dataForm = formFieldsInitialState())
        .finally(() => { 
          this.isLoad.addCarModel = false 
        })
          .then(() => { this.isAddModel = false })
    },

    handleDeleteCarBrand (brand) {
      this.openSwal("sureRemoveCarMark")
        .then(yes => yes && 
        this.deleteCarManufacturer(brand).then(() => this.$notification.success(this.$i18n.t('notify.markRemoved')))
      )
    },
    /** Delete car model */
    handleDeleteCarModel (model, indexBrand) {
      const realIndex = this.getRealBrandIndex(indexBrand)
      const data = {  uid: model.uid, indexBrand:realIndex  }
      this.openSwal("sureRemoveCarModel")
        .then(yes => yes && 
        this.deleteCarModel(data).then(() => this.$notification.success(this.$i18n.t('notify.modelRemoved')))
      )
    },

    getRealBrandIndex (index) {
      return index + (this.page - 1) * this.pageSize
    },

    addPropModel (index, data = []) {
      let realIndex = this.searchText === '' ? this.getRealBrandIndex(index) : index
      this.$set(this.$store.state.base.carBrand[realIndex], 'models', data)
    },
    findIndexBrandById (uid) {
      return this.carBrandsList.findIndex((item => item.uid === uid))
    },
    revealCarModel (uid, index) {
      const params = new URLSearchParams()
      const findedBrandIndex = this.searchText === '' ? index : this.findIndexBrandById(uid)
      params.set('uid', uid)
      this.$api.get(`admin/manufacturer/?${params}`).then(response => {
        response.data.models.length ?
        this.addPropModel(findedBrandIndex, response.data.models) :
        this.addPropModel(findedBrandIndex)
      })
    },

    initPage () {
      this.listCount = this.carBrandsList.length
      this.listCount < this.pageSize ? (this.pageList = this.carBrandsList)  :
      this.pageList =  this.carBrandsList.slice(0, this.pageSize)
    },

    updatePage (pageIndex) {
      this.searchText = ''
      let from = (pageIndex - 1) * this.pageSize
      let to = pageIndex * this.pageSize
      this.pageList = this.carBrandsList.slice(from, to)
      this.page = pageIndex
    }
  },
}
</script>
