<template lang="pug">
  include ../../../../assets/pug/index
 
  v-card.dropdown-block
    v-card-actions.pa-3.pb-0.justify-end
      v-icon.cursor-pointer(@click="$emit('closed', false)") mdi-close
    v-card-text.px-4.pb-4
      +h2('createCarManufacturer').mb-8
      .d-flex.justify-center.align-end.flex-column
        +input('dataForm.carBrand', 'enterCarMark')
        +btn('primary', 'btn.add')(
          @click="handleAdd"
          :loading="isLoad").btn-primary.mt-4
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Auto",
  props: {
    handleAdd: Function,
    dataForm: Object,
    isLoad: Boolean
  },
  data () {
    return {
    }
  },

  methods: {
    ...mapActions(['addCarManufacturer']),
  },
}
</script>
